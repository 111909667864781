import { defineStore } from "pinia";
import ApiService from "@/core/services/apiServicev3";
import posConfig from "@/views/pos/config/pos";

interface RootState {
  data: null;
  paymentId: any;
  isActive: boolean;
  isLoading: boolean;
  isConnecting: boolean;
  isPaying: boolean;
  isCancelling: boolean;
  isFinished: boolean;
  isError: {
    state: boolean;
    message: string | null;
  }
}

export const useTerminalStore = defineStore({
  id: "terminalStore",
  state: () =>
  ({
    data: null,
    paymentId: null,
    isActive: false,
    isLoading: false,
    isConnecting: false,
    isPaying: false,
    isCancelling: false,
    isFinished: false,
    isError: {
      state: false,
      message: null,
    }
  } as RootState),
  getters: {
  },
  actions: {
    reset() {
      this.isError.state = false;
      this.isPaying = false;
      this.isCancelling = false;
      this.isConnecting = false;
      this.isFinished = false;
    },
    async validatePayment(paymentId: number) {

      this.paymentId = null; // Reset
      this.reset();

      const response = {
        status: 'error',
        data: null
      } as any

      if (paymentId) {
        return new Promise((resolve, reject) => {

          this.paymentId = paymentId;
          this.isLoading = true;
          this.isConnecting = true;

          let amountChecks = 0;
          let documentChecks = 0;
          const interval = setInterval(async () => {
            amountChecks += 1
            if (amountChecks === posConfig.validatePaymentTotalChecks) {

              clearInterval(interval);
              resolve(response);
            } else {
              try {
                await ApiService.get('apiUrlSearch', `/indexes/sales_payments/documents/${paymentId}`)
                  .then(({ data }) => {

                    const { payment_status } = data;
                    documentChecks++

                    this.isConnecting = false;
                    this.isPaying = true;

                    console.log(payment_status)

                    if (payment_status !== 'pending') {
                      response.status = 'success';
                      if (payment_status === 'canceled') {
                        let errorMessage;

                        if (documentChecks < 4) {
                          errorMessage = "Terminal_no_response"
                        } else {
                          errorMessage = "Terminal_session_cancelled"
                        }

                        this.isError = {
                          state: true,
                          message: errorMessage
                        }

                        response.status = 'error'
                      }

                      this.isPaying = false;
                      this.isCancelling = false;
                      this.isFinished = true;
                      clearInterval(interval);
                      this.isLoading = false; // Set isLoading to false if paymentId is falsy

                      response.data = data
                      resolve(response);
                    }
                  })
              } catch (error: any) {
                if (error && error.response.status === 404) {
                  return;
                }
                clearInterval(interval);
                this.isLoading = false; // Set isLoading to false if paymentId is falsy
                reject(response);
              }
            }
          }, posConfig.validatePaymentCheckTimer);
        });
      } else {
        this.isLoading = false; // Set isLoading to false if paymentId is falsy
        return response;
      }
    },
    async cancelPayment(paymentId: number) {
      // TODO: TerminalID

      try {
        this.isCancelling = true;
        await ApiService.post("apiUrl", "/sales/payment/cancel-pin", { id: paymentId })
      }
      catch(error) {
        this.isCancelling = false;
      }
      finally {
        this.reset();
      }
    }
  }
});