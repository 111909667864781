import { defineStore } from "pinia";
import ApiService from "@/core/services/apiServicev3";
import { useSalesStore } from "./sales";

interface RootState {
  data: null
  invoiceableLines: any
}

type InvoiceLine = {
  id: number;
  quantity: number;
};


export const useInvoiceStore = defineStore({
  id: "invoiceStore",
  state: () =>
  ({
    data: null,
    invoiceableLines: []
  } as RootState),
  getters: {
    totalQuantityInvoicingLines(state) {
      return state.invoiceableLines.reduce((total, line) => {
        return total + Math.abs(line.invoiceQuantity);
      }, 0);
    },
    totalAmountInvoicingLines(state) {
      return state.invoiceableLines.reduce((total, line) => {
        return total + (line.price * line.invoiceQuantity);
      }, 0);
    },
    totalQuantityInvoiceableLines(state) {
      return state.invoiceableLines.length;
    },
    totalQuantityInvoiceableItems(state) {
      return state.invoiceableLines.reduce((total, line) => {
        return total + Math.abs(line.quantity);
      }, 0);
    },
  },
  actions: {
    async getSaleInvoiceableLines(saleLines: any) {
      console.log(saleLines)
      const list = [] as any

      for (const line of saleLines) {
        const lineQuantity = parseFloat(line.quantity);
        const billedQuantity = parseFloat(line.billed_quantity);
        const totalInclVat = parseFloat(line.net_total_with_vat);

        const item = {
          id: line.id,
          productId: line.product_id,
          name: line.name,
          quantity: lineQuantity,
          price: totalInclVat / lineQuantity,
          invoiceQuantity: lineQuantity - billedQuantity,
          range: { min: -billedQuantity, max: lineQuantity - billedQuantity },
        }

        list.push(item)
      }

      this.invoiceableLines = list;
    },
    formatInvoiceableLines(lines) {
      return lines
        .filter(line => line.invoiceQuantity !== 0)
        .map(line => ({ id: line.id, quantity: `${line.invoiceQuantity}` }));
    },
    async createInvoice(saleId: number, invoiceableLines: InvoiceLine[]): Promise<{ status: string, message: string | null, data: any }> {

      const response = {
        status: 'error',
        message: null,
        data: null
      }

      try {
        const payload = {
          sale_id: saleId,
          sale_lines: invoiceableLines
        }

        const { data } = await ApiService.post('apiUrl', "/sales/invoice", payload)

        response.status = 'success';
        response.data = data;
      }
      catch (err: any) {
        // 
        console.log(err)

        response.message = err.response.data.result.errors;
      }

      return response;
    },
    async createInvoicePayment(invoiceId: number, paymentId: number, paymentAmount: number): Promise<{ status: string, data: any }> {

      const response = {
        status: 'error',
        data: null
      }

      try {
        const payload = {
          payment_id: paymentId,
          invoice_id: invoiceId,
          amount: paymentAmount
        }

        const { data } = await ApiService.post("apiUrl", "/sales/invoices/payment", payload);

        response.status = 'success'
        response.data = data;
      }
      catch (error) {
        //
      }

      return response;
    }
  }
});