import { defineStore } from "pinia";
import { nextTick } from "vue";
import { Modal } from "bootstrap";
import { useContentStore } from "../content";

interface RootState {
  modals: any
}

export const useContentModalsStore = defineStore({
  id: "contentModalsStore",
  state: () =>
  ({
    modals: [
      // { id: 'es_modal_sales_create', isActive: false, refreshKey: 0, componentName: 'SalesTable' }
    ]
  } as RootState),
  getters: {
    // modalStatus(state) {
    //   return state.init;
    // },

  },
  actions: {
    initModal(id, config, componentName?: string) {
      const modal = this.modals.find(modal => modal.id === id);

      if (modal) {
        modal.isActive = true;
        modal.refreshKey++
      } else {
        this.modals.push({
          id: id,
          isActive: true,
          refreshKey: 0,
          componentName: componentName,
          type: config.type,
          custom: config.custom,
          data: config.data
        })
      }
    },
    openModal(id: string, config: { type: 'modal' | 'panel', custom?: boolean, data?: any } = { type: 'modal', custom: false, data: null }, componentName?: string) {
      const contentStore = useContentStore();

      document.body.classList.add('overflow-hidden');
      contentStore.isOverlay = true;

      this.initModal(id, config, componentName)
    },
    closeModal(id) {
      const contentStore = useContentStore();
      // nextTick(() => {
      //   const modal = document.getElementById(id);

      //   if (modal) {
      //     const instance = Modal.getOrCreateInstance(modal)
      //     instance.toggle();
      //   }
      // })

      this.modals = this.modals.filter(modal => modal.id !== id);
      document.body.classList.remove('overflow-hidden');
      contentStore.isOverlay = false;
    },
    currentModal(id) {
      const modal = this.modals.find((modal) => modal.id === id);

      return modal || { id, isActive: false, refreshKey: 0 };
    },
    closeModals() {
      console.log('close')
      const contentStore = useContentStore();
      for (const modal of this.modals) {
        if (modal.isActive) {
          modal.isTransition = true;

          setTimeout(() => {
            modal.isTransition = false;
            modal.isActive = false;

          }, 100)
        }
      }

      contentStore.isOverlay = false;
      document.body.classList.remove('overflow-hidden')
    }
  }
});