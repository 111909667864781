import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import store from "@/store";
import { Mutations, Actions } from "@/store/enums/StoreEnums";
import { useContentStore } from "@/stores/content";
import { useAppComponentsStore } from "@/stores/app/components/components";
import { useAuthStore } from "@/stores/auth";
import { useToolbarStore } from "@/stores/toolbar";
import axios from "axios"


const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/dashboard",
    component: () => import("@/layout/Layout.vue"),
    meta: {
      middleware: "auth",
    },
    children: [
      {
        path: "/dashboard",
        name: "dashboard",
        component: () => import("@/views/Dashboard.vue")
      },
      {
        path: "/sales",
        name: "sales",
        component: () => import("@/views/sales/Sales.vue"),
        meta: { breadcrumb: '' },
        alias: ['filter']
      },
      {
        path: "/sales/invoices",
        name: "",
        component: () => import("@/views/sales/invoices/Invoices.vue")
      },
      {
        path: "/sales/invoices/:uid",
        name: "",
        component: () => import("@/views/sales/invoices/Invoice.vue"),
        children: [
          {
            path: "general",
            name: '',
            component: () => import("@/views/sales/invoices/sub/General.vue"),
            alias: ['']
          },
          {
            path: "lines",
            name: "",
            component: () => import("@/views/sales/invoices/sub/Lines.vue"),
          },
          {
            path: "payments",
            name: "",
            component: () => import("@/views/sales/invoices/sub/Payments.vue"),
          },
        ]
      },
      {
        path: "/sales/quotations",
        name: "",
        component: () => import("@/views/sales/quotations/Quotations.vue")
      },
      {
        path: "/sales/quotations/:uid",
        name: "",
        component: () => import("@/views/sales/quotations/Quotation.vue"),
        children: [
          {
            path: "general",
            name: '',
            component: () => import("@/views/sales/quotations/sub/General.vue"),
            alias: ['']
          },
          {
            path: "customer",
            name: "",
            component: () => import("@/views/sales/quotations/sub/Customer.vue"),
          },
          {
            path: "lines",
            name: "",
            component: () => import("@/views/sales/quotations/sub/Lines.vue"),
          },
          {
            path: "files",
            name: "",
            component: () => import("@/views/sales/quotations/sub/Files.vue"),
          },
          {
            path: "notifications",
            name: "",
            component: () => import("@/views/sales/quotations/sub/Notifications.vue"),
          },
        ]
      },
      {
        path: "/sales/payments",
        name: "",
        component: () => import("@/views/sales/payments/Payments.vue")
      },
      {
        path: "/returns",
        name: "returns",
        component: () => import("@/views/returns/Returns.vue")
      },
      {
        path: "/returns/:uid",
        name: "",
        component: () => import("@/views/returns/Return.vue"),
        children: [
          {
            path: "general",
            name: "",
            component: () => import("@/views/returns/sub/General.vue"),
            alias: ['']
          },
          {
            path: "lines",
            name: "",
            component: () => import("@/views/returns/sub/Lines.vue"),
          },
          {
            path: "payments",
            name: "",
            component: () => import("@/views/returns/sub/Payments.vue"),
          },
          {
            path: "labels",
            name: "",
            component: () => import("@/views/returns/sub/Labels.vue"),
          },
          {
            path: "attachments",
            name: "",
            component: () => import("@/views/returns/sub/Attachments.vue"),
          },
          {
            path: "logs",
            name: "",
            component: () => import("@/views/returns/sub/Logs.vue"),
          },
        ]
      },
      {
        path: '/sales/:uid',
        component: () => import("@/views/sales/Sale.vue"),
        meta: { breadcrumb: '' },
        children: [
          {
            path: "general",
            name: "",
            component: () => import("@/views/sales/sub/General.vue"),
            meta: { breadcrumb: '' },
            alias: ['']
          },
          {
            path: "customer",
            name: "",
            component: () => import("@/views/sales/sub/Customer.vue"),
            meta: { breadcrumb: '' },
          },
          {
            path: "lines",
            name: "",
            component: () => import("@/views/sales/sub/Lines.vue"),
            meta: { breadcrumb: '' },
          },
          {
            path: "invoices",
            name: "",
            component: () => import("@/views/sales/sub/Invoices.vue"),
          },
          {
            path: "payments",
            name: "",
            component: () => import("@/views/sales/sub/Payments.vue"),
          },
          {
            path: "labels",
            name: "",
            component: () => import("@/views/sales/sub/Labels.vue"),
          },
          {
            path: "picklists",
            name: "",
            component: () => import("@/views/sales/sub/Picklists.vue"),
          },
          {
            path: "shipments",
            name: "",
            component: () => import("@/views/sales/sub/Shipments.vue"),
          },
          {
            path: "returns",
            name: "",
            component: () => import("@/views/sales/sub/Returns.vue"),
          },
          {
            path: "notifications",
            name: "",
            component: () => import("@/views/sales/sub/Notifications.vue"),
          }
        ],
      },
      {
        path: "/customers",
        name: "",
        component: () => import("@/views/customers/Customers.vue"),
      },
      {
        path: "/customers/discounts",
        name: "",
        component: () => import("@/views/customers/discounts/Discounts.vue")
      },
      {
        path: "/customers/discounts/:uid",
        name: "",
        component: () => import("@/views/customers/discounts/Discount.vue"),
        children: [
          {
            path: "general",
            name: "",
            component: () => import("@/views/customers/discounts/sub/General.vue"),
            alias: ['']
          },
          {
            path: "rules",
            name: "",
            component: () => import("@/views/customers/discounts/sub/Rules.vue"),
          },
          {
            path: "customers",
            name: "",
            component: () => import("@/views/customers/discounts/sub/Customers.vue"),
          },
        ]
      },
      {
        path: "/customers/subscriptions",
        name: "",
        component: () => import("@/views/customers/subscriptions/Subscriptions.vue")
      },
      {
        path: "/customers/subscriptions/:uid",
        name: "",
        component: () => import("@/views/customers/subscriptions/Subscription.vue"),
        children: [
          {
            path: "general",
            name: "",
            component: () => import("@/views/customers/subscriptions/sub/General.vue"),
            alias: ['']
          },
          {
            path: "lines",
            name: "",
            component: () => import("@/views/customers/subscriptions/sub/Lines.vue"),
          },
          {
            path: "sales",
            name: "",
            component: () => import("@/views/customers/subscriptions/sub/Sales.vue"),
          },
        ]
      },
      {
        path: '/customers/:uid',
        component: () => import("@/views/customers/Customer.vue"),
        children: [
          {
            path: "general",
            name: "",
            component: () => import("@/views/customers/sub/General.vue"),
            alias: ['']
          },
          {
            path: "sales",
            name: "",
            component: () => import("@/views/customers/sub/Sales.vue"),
          },
          {
            path: "invoices",
            name: "",
            component: () => import("@/views/customers/sub/Invoices.vue"),
          },
          {
            path: "quotations",
            name: "quotations",
            component: () => import("@/views/customers/sub/Quotations.vue"),
          },
          {
            path: "subscriptions",
            name: "",
            component: () => import("@/views/customers/sub/Subscriptions.vue"),
          },
          {
            path: "addresses",
            name: "",
            component: () => import("@/views/customers/sub/Addresses.vue"),
          },
          {
            path: "discounts",
            name: "",
            component: () => import("@/views/customers/sub/Discounts.vue"),
          },
          {
            path: "picklists",
            name: "",
            component: () => import("@/views/customers/sub/Picklists.vue"),
          },
          {
            path: "shipments",
            name: "",
            component: () => import("@/views/customers/sub/Shipments.vue"),
          },
          {
            path: "returns",
            name: "",
            component: () => import("@/views/customers/sub/Returns.vue"),
          },
          {
            path: "labels",
            name: "",
            component: () => import("@/views/customers/sub/Labels.vue"),
          },
          {
            path: "notifications",
            name: "",
            component: () => import("@/views/customers/sub/Notifications.vue"),
          },
          {
            path: "reports",
            name: "",
            component: () => import("@/views/customers/sub/Reports.vue"),
          },
        ]
      },
      {
        path: "/products",
        name: "products",
        component: () => import("@/views/products/Products.vue"),
      },
      {
        path: '/products/specifications/:uid',
        component: () => import("@/views/products/specifications/Specification.vue"),
        children: [
          {
            path: "general",
            name: "",
            component: () => import("@/views/products/specifications/sub/General.vue"),
            alias: ['']
          },
          {
            path: "options",
            name: "",
            component: () => import("@/views/products/specifications/sub/Options.vue"),
          },
          {
            path: "products",
            name: "",
            component: () => import("@/views/products/specifications/sub/Products.vue"),
          },
        ]
      },
      {
        path: '/products/suppliers/:uid',
        component: () => import("@/views/products/suppliers/Supplier.vue"),
        children: [
          {
            path: "general",
            name: "",
            component: () => import("@/views/products/suppliers/sub/General.vue"),
            alias: ['']
          },
          {
            path: "products",
            name: "",
            component: () => import("@/views/products/suppliers/sub/Products.vue"),
          },
          {
            path: "purchases",
            name: "",
            component: () => import("@/views/products/suppliers/sub/Purchases.vue"),
          }
        ]
      },
      {
        path: '/products/suppliers/purchases/:uid',
        component: () => import("@/views/products/purchases/Purchase.vue"),
        children: [
          {
            path: "general",
            name: "",
            component: () => import("@/views/products/purchases/sub/General.vue"),
            alias: ['']
          },
          {
            path: "lines",
            name: "",
            component: () => import("@/views/products/purchases/sub/Lines.vue"),
          }
        ]
      },
      {
        path: '/products/stocks/locations/:uid',
        component: () => import("@/views/products/locations/Location.vue"),
        children: [
          {
            path: "general",
            name: "",
            component: () => import("@/views/products/locations/sub/General.vue"),
            alias: ['']
          },
          {
            path: "stocks",
            name: "",
            component: () => import("@/views/products/locations/sub/Stocks.vue"),
          },
          {
            path: "history",
            name: "",
            component: () => import("@/views/products/locations/sub/History.vue"),
          }
        ]
      },
      // {
      //   path: '/products/tickets/:uid',
      //   component: () => import("@/views/products/locations/Location.vue"),
      //   children: [
      //     {
      //       path: "general",
      //       name: "",
      //       component: () => import("@/views/products/locations/sub/General.vue"),
      //       alias: ['']
      //     },
      //   ]
      // },
      {
        path: '/products/:uid',
        component: () => import("@/views/products/Product.vue"),
        children: [
          {
            path: "general",
            name: "",
            component: () => import("@/views/products/sub/General.vue"),
            alias: ['']
          },
          {
            path: "descriptions",
            name: "",
            component: () => import("@/views/products/sub/Descriptions.vue"),
          },
          {
            path: "specifications",
            name: "",
            component: () => import("@/views/products/sub/Specifications.vue"),
          },
          {
            path: "categories",
            name: "",
            component: () => import("@/views/products/sub/Categories.vue"),
          },
          {
            path: "prices",
            name: "",
            component: () => import("@/views/products/sub/Prices.vue"),
          },
          {
            path: "suppliers",
            name: "",
            component: () => import("@/views/products/sub/Suppliers.vue"),
          },
          {
            path: "packaging",
            name: "",
            component: () => import("@/views/products/sub/Packaging.vue"),
          },
          {
            path: "media",
            name: "",
            component: () => import("@/views/products/sub/Media.vue"),
          },
          {
            path: "variations",
            name: "",
            component: () => import("@/views/products/sub/Variations.vue"),
          },
          {
            path: "options",
            name: "",
            component: () => import("@/views/products/sub/Options.vue"),
          },
          {
            path: "stocks",
            name: "",
            component: () => import("@/views/products/sub/Stocks.vue"),
          },
          {
            path: "webshop",
            name: "",
            component: () => import("@/views/products/sub/Webshop.vue"),
          },
          {
            path: "sales",
            name: "",
            component: () => import("@/views/products/sub/Sales.vue"),
          },
          {
            path: "tickets",
            name: "",
            component: () => import("@/views/products/sub/Tickets.vue"),
          },
          {
            path: "settings",
            name: "",
            component: () => import("@/views/products/sub/Settings.vue"),
          },
          {
            path: "items",
            name: "",
            component: () => import("@/views/products/sub/Items.vue"),
          },
          {
            path: "relations",
            name: "",
            component: () => import("@/views/products/sub/Relations.vue"),
          },
        ]
      },
      {
        path: "/products/specifications",
        name: "",
        component: () => import("@/views/products/specifications/Specifications.vue"),
      },
      {
        path: "/products/options",
        name: "",
        component: () => import("@/views/products/options/Options.vue"),
      },
      {
        path: "/products/suppliers",
        name: "",
        component: () => import("@/views/products/suppliers/Suppliers.vue"),
      },
      {
        path: "/products/stocks",
        name: "",
        component: () => import("@/views/products/stocks/Stocks.vue"),
      },
      {
        path: "/products/locations",
        name: "",
        component: () => import("@/views/products/locations/Locations.vue"),
      },
      {
        path: "/products/tickets",
        name: "",
        component: () => import("@/views/products/tickets/Tickets.vue"),
        children: [
          {
            path: '/products/tickets/:uid',
            component: () => import("@/views/products/locations/Location.vue"),
            children: [
              {
                path: "general",
                name: "",
                component: () => import("@/views/products/locations/sub/General.vue"),
                alias: ['']
              },
            ]
          }
        ]
      },
      {
        path: "/products/purchases",
        name: "",
        component: () => import("@/views/products/purchases/Purchases.vue"),
      },
      {
        path: "/picklists",
        name: "picklists",
        component: () => import("@/views/picklists/Picklists.vue"),
      },
      {
        path: '/picklists/:uid',
        component: () => import("@/views/picklists/Picklist.vue"),
        children: [
          {
            path: "general",
            name: "",
            component: () => import("@/views/picklists/sub/General.vue"),
            alias: ['']
          },
          {
            path: "lines",
            name: "",
            component: () => import("@/views/picklists/sub/Lines.vue"),
          },
        ]
      },
      {
        path: "/shipments",
        name: "shipments",
        component: () => import("@/views/shipments/Shipments.vue"),
      },
      {
        path: '/shipments/:uid',
        component: () => import("@/views/shipments/Shipment.vue"),
        children: [
          {
            path: "general",
            name: "",
            component: () => import("@/views/shipments/sub/General.vue"),
            alias: ['']
          },
          {
            path: "lines",
            name: "",
            component: () => import("@/views/shipments/sub/Lines.vue"),
          },
          {
            path: "packages",
            name: "",
            component: () => import("@/views/shipments/sub/Packages.vue"),
          },
          {
            path: "labels",
            name: "",
            component: () => import("@/views/shipments/sub/Labels.vue"),
          },
          {
            path: "attachments",
            name: "",
            component: () => import("@/views/shipments/sub/Attachments.vue"),
          },
        ]
      },
      {
        path: "/labels",
        name: "labels",
        component: () => import("@/views/labels/Labels.vue"),
      },
      {
        path: "/pos",
        component: () => import("@/views/pos/Overview.vue"),
      },
      {
        path: "/pos/sessions",
        component: () => import("@/views/pos/sub/sessions/Sessions.vue"),
      },
      {
        path: "/pos/cashiers",
        component: () => import("@/views/pos/sub/cashiers/Cashiers.vue"),
      },
      {
        path: "/pos/reports",
        component: () => import("@/views/pos/sub/reports/Reports.vue")
      },
      {
        path: "/pos/sessions/:uid",
        component: () => import("@/views/pos/sub/sessions/Session.vue"),
        children: [
          {
            path: "general",
            name: "",
            component: () => import("@/views/pos/sub/sessions/sub/General.vue"),
            alias: ['']
          },
          {
            path: "payments",
            name: "",
            component: () => import("@/views/pos/sub/sessions/sub/Payments.vue"),
          },
          {
            path: "cash-history",
            name: "",
            component: () => import("@/views/pos/sub/sessions/sub/CashHistory.vue"),
          },
        ]
      },
      {
        path: "/pos/:posId/checkout",
        component: () => import("@/views/pos/sub/overview/POS.vue")
      },
      {
        path: "/pos/:posId/checkout/:saleId",
        component: () => import("@/views/pos/sub/checkout/Checkout.vue"),
        props: { toolbar: true }
      },
      {
        path: "/pos/:posId",
        component: () => import("@/views/pos/PointofSale.vue"),
        children: [
          {
            path: "general",
            name: "",
            component: () => import("@/views/pos/sub/General.vue"),
            alias: ['']
          },
          {
            path: "settings",
            name: "",
            component: () => import("@/views/pos/sub/Settings.vue"),
          },
        ]
      },
      {
        path: "/webshop",
        component: () => import("@/views/webshop/Webshops.vue"),
      },


      {
        path: '/webshop/:uid',
        component: () => import("@/views/webshop/Webshop.vue"),
        children: [
          {
            path: "general",
            name: "",
            component: () => import("@/views/webshop/sub/General.vue"),
            alias: ['']
          },
          {
            path: "posts",
            name: "",
            component: () => import("@/views/webshop/sub/Posts.vue"),
          },
          {
            path: "pages",
            name: "",
            component: () => import("@/views/webshop/sub/Pages.vue"),
          },
          {
            path: "categories",
            name: "",
            component: () => import("@/views/webshop/sub/Categories.vue"),
          },
          {
            path: "menus",
            name: "",
            component: () => import("@/views/webshop/sub/Menus.vue"),
          },
          {
            path: "products",
            name: "",
            component: () => import("@/views/webshop/sub/Products.vue"),
          },
          {
            path: "specifications",
            name: "",
            component: () => import("@/views/webshop/sub/Specifications.vue"),
          },
          {
            path: "sales",
            name: "",
            component: () => import("@/views/webshop/sub/Sales.vue"),
          },
          {
            path: "payments",
            name: "",
            component: () => import("@/views/webshop/sub/Payments.vue"),
          },
          {
            path: "display",
            name: "",
            component: () => import("@/views/webshop/sub/Display.vue"),
          },
          {
            path: "forms",
            name: "",
            component: () => import("@/views/webshop/sub/Forms.vue"),
          },
          {
            path: "scripts",
            name: "",
            component: () => import("@/views/webshop/sub/Scripts.vue"),
          },
          {
            path: "widgets",
            name: "",
            component: () => import("@/views/webshop/sub/Widgets.vue"),
          },
          {
            path: "shipping-options",
            name: "",
            component: () => import("@/views/webshop/sub/ShippingOptions.vue"),
          },
          {
            path: "coupons",
            name: "",
            component: () => import("@/views/webshop/sub/Coupons.vue"),
          }
        ]
      },

      {
        path: '/webshop/posts/categories/:uid',
        component: () => import("@/views/webshop/posts/categories/Category.vue"),
        children: [
          {
            path: "general",
            name: "",
            component: () => import("@/views/webshop/posts/categories/sub/General.vue"),
            alias: ['']
          },
        ]
      },
      {
        path: '/webshop/pages/:uid',
        component: () => import("@/views/webshop/pages/Page.vue"),
        children: [
          {
            path: "general",
            name: "",
            component: () => import("@/views/webshop/pages/sub/General.vue"),
            alias: ['']
          },
          {
            path: "components",
            name: "",
            component: () => import("@/views/webshop/pages/sub/Components.vue"),
          },
        ]
      },
      {
        path: '/webshop/forms/:uid',
        component: () => import("@/views/webshop/forms/Form.vue"),
        children: [
          {
            path: "general",
            name: "",
            component: () => import("@/views/webshop/forms/sub/General.vue"),
            alias: ['']
          },
          {
            path: "fields",
            name: "",
            component: () => import("@/views/webshop/forms/sub/Fields.vue"),
          },
          {
            path: "entries",
            name: "",
            component: () => import("@/views/webshop/forms/sub/Entries.vue"),
          },
          {
            path: "notifications",
            name: "",
            component: () => import("@/views/webshop/forms/sub/Notifications.vue"),
          },
        ]
      },
      {
        path: '/webshop/categories/:uid',
        component: () => import("@/views/webshop/categories/Category.vue"),
        children: [
          {
            path: "general",
            name: "",
            component: () => import("@/views/webshop/categories/sub/General.vue"),
            alias: ['']
          },
          {
            path: "descriptions",
            name: "",
            component: () => import("@/views/webshop/categories/sub/Descriptions.vue"),
          },
          {
            path: "specifications",
            name: "",
            component: () => import("@/views/webshop/categories/sub/Specifications.vue")
          },
          {
            path: "landing-page",
            name: "",
            component: () => import("@/views/webshop/categories/sub/Landing-page.vue")
          },
          {
            path: "products",
            name: "",
            component: () => import("@/views/webshop/categories/sub/Products.vue")
          },
          {
            path: "subcategories",
            name: "",
            component: () => import("@/views/webshop/categories/sub/Subcategories.vue")
          },
        ]
      },
      {
        path: '/webshop/menus/:uid',
        component: () => import("@/views/webshop/menus/Menu.vue"),
        children: [
          {
            path: "general",
            name: "",
            component: () => import("@/views/webshop/menus/sub/General.vue"),
            alias: ['']
          },
          {
            path: "items",
            name: "",
            component: () => import("@/views/webshop/menus/sub/Items.vue"),
          },
        ]
      },
      {
        path: '/webshop/posts/:uid',
        component: () => import("@/views/webshop/posts/Post.vue"),
        children: [
          {
            path: "general",
            name: "",
            component: () => import("@/views/webshop/posts/sub/General.vue"),
            alias: ['']
          },
          {
            path: "categories",
            name: "",
            component: () => import("@/views/webshop/posts/sub/Categories.vue"),
          },
          {
            path: "media",
            name: "",
            component: () => import("@/views/webshop/posts/sub/Media.vue"),
          },
        ]
      },
      {
        path: '/webshop/shipping-options/:uid',
        component: () => import("@/views/webshop/shipping-options/ShippingOption.vue"),
        children: [
          {
            path: "general",
            name: "",
            component: () => import("@/views/webshop/shipping-options/sub/General.vue"),
            alias: ['']
          },
          {
            path: "rules",
            name: "",
            component: () => import("@/views/webshop/shipping-options/sub/Rules.vue"),
          },
        ]
      },
      {
        path: "/management",
        name: "management",
        component: () => import("@/views/management/Management.vue"),
        children: [
          {
            path: "",
            name: "",
            component: () => import("@/views/management/overview/Overview.vue"),
          },
          {
            path: "profiles",
            name: "",
            component: () => import("@/views/management/profiles/Profiles.vue"),
          },
          {
            path: 'profiles/:uid',
            component: () => import("@/views/management/profiles/Profile.vue"),
            children: [
              {
                path: "general",
                name: "",
                component: () => import("@/views/management/profiles/sub/General.vue"),
                alias: ['']
              },
              {
                path: "connections",
                name: "",
                component: () => import("@/views/management/profiles/sub/Connections.vue"),
              },
              {
                path: "support",
                name: "",
                component: () => import("@/views/management/profiles/sub/Support.vue")
              },
              {
                path: "paymentmethods",
                name: "",
                component: () => import("@/views/management/profiles/sub/PaymentMethods.vue")
              },
              {
                path: "addresses",
                name: "",
                component: () => import("@/views/management/profiles/sub/Addresses.vue")
              },
              {
                path: "sale-settings",
                name: "",
                component: () => import("@/views/management/profiles/sub/SaleSettings.vue")
              },
              {
                path: "emails",
                name: "",
                component: () => import("@/views/management/profiles/sub/Emails.vue")
              },
            ]
          },
          {
            path: "connections",
            name: "",
            component: () => import("@/views/management/connections/Connections.vue"),
          },
          {
            path: 'connections/:uid',
            component: () => import("@/views/management/connections/Connection.vue"),
            children: [
              {
                path: "general",
                name: "",
                component: () => import("@/views/management/connections/sub/General.vue"),
                alias: ['']
              },
              {
                path: "settings",
                name: "",
                component: () => import("@/views/management/connections/sub/Settings.vue"),
              },
              {
                path: "sales",
                name: "",
                component: () => import("@/views/management/connections/sub/Sales.vue"),
              },
              {
                path: "payments",
                name: "",
                component: () => import("@/views/management/connections/sub/Payments.vue"),
              },
              {
                path: "products",
                name: "",
                component: () => import("@/views/management/connections/sub/Products.vue"),
              },
              {
                path: "automations",
                name: "",
                component: () => import("@/views/management/connections/sub/Automations.vue"),
              },
              {
                path: "mutations",
                name: "",
                component: () => import("@/views/management/connections/sub/Mutations.vue"),
              },
              {
                path: "invoices",
                name: "",
                component: () => import("@/views/management/connections/sub/Invoices.vue"),
              },
            ]
          },
          {
            path: "automations",
            name: "",
            component: () => import("@/views/management/automations/Automations.vue"),
          },
          {
            path: 'automations/:uid',
            component: () => import("@/views/management/automations/Automation.vue"),
            children: [
              {
                path: "general",
                name: "",
                component: () => import("@/views/management/automations/sub/General.vue"),
                alias: ['']
              },
              {
                path: "settings",
                name: "",
                component: () => import("@/views/management/automations/sub/Settings.vue"),
              },
              {
                path: "logs",
                name: "",
                component: () => import("@/views/management/automations/sub/Logs.vue"),
              },
            ]
          },
          {
            path: "tags",
            name: "",
            component: () => import("@/views/management/tags/Tags.vue"),
          },
          {
            path: "users",
            name: "",
            component: () => import("@/views/management/users/Users.vue"),
          },
          {
            path: 'users/roles/:uid',
            component: () => import("@/views/management/users/roles/Role.vue"),
            children: [
              {
                path: "general",
                name: "",
                component: () => import("@/views/management/users/roles/sub/General.vue"),
                alias: ['']
              },
              {
                path: "users",
                name: "",
                component: () => import("@/views/management/users/roles/sub/Users.vue"),
              },
              {
                path: "permissions",
                name: "",
                component: () => import("@/views/management/users/roles/sub/Permissions.vue"),
              },
            ]
          },
          {
            path: 'users/:uid',
            component: () => import("@/views/management/users/User.vue"),
            children: [
              {
                path: "general",
                name: "",
                component: () => import("@/views/management/users/sub/General.vue"),
                alias: ['']
              },
              {
                path: "login",
                name: "",
                component: () => import("@/views/management/users/sub/Login.vue"),
              },
              {
                path: "settings",
                name: "",
                component: () => import("@/views/management/users/sub/Settings.vue"),
              },
              {
                path: "access",
                name: "",
                component: () => import("@/views/management/users/sub/Access.vue"),
              },
            ]
          },
          {
            path: "reports",
            name: "",
            component: () => import("@/views/management/reports/Reports.vue"),
          },
          {
            path: "pickup-locations",
            name: "",
            component: () => import("@/views/management/pickup-locations/Pickup-Locations.vue"),
          },
          {
            path: "account",
            component: () => import("@/views/management/account/Account.vue"),
            children: [
              {
                path: "general",
                name: "",
                component: () => import("@/views/management/account/sub/General.vue"),
                alias: ['']
              },
              {
                path: "subscriptions",
                name: "",
                component: () => import("@/views/management/account/sub/Subscriptions.vue"),
              },
              {
                path: "invoices",
                name: "",
                component: () => import("@/views/management/account/sub/Invoices.vue"),
              },
              {
                path: "emails",
                name: "",
                component: () => import("@/views/management/account/sub/Emails.vue"),
              },
              {
                path: "tasks",
                name: "",
                component: () => import("@/views/management/account/sub/Tasks.vue"),
              },
              {
                path: "logs",
                name: "",
                component: () => import("@/views/management/account/sub/Logs.vue"),
              },
              {
                path: "files",
                name: "",
                component: () => import("@/views/management/account/sub/Files.vue"),
              },
              {
                path: "limits",
                name: "",
                component: () => import("@/views/management/account/sub/Limits.vue"),
              },
              {
                path: "settings",
                name: "",
                component: () => import("@/views/management/account/sub/Settings.vue"),
              }
            ]
          },
          {
            path: "files",
            name: "",
            component: () => import("@/views/management/files/Files.vue"),
          },
        ]
      },
      {
        path: "tasks",
        name: "",
        component: () => import("@/views/tasks/Tasks.vue"),
      },
      {
        path: '/tasks/:uid',
        component: () => import("@/views/tasks/Task.vue"),
        children: [
          {
            path: "general",
            component: () => import("@/views/tasks/sub/General.vue"),
            alias: ['']
          },
        ]
      },
      {
        path: '/builder',
        name: "",
        component: () => import("@/views/builder/Builder.vue"),
      },
      {
        path: '/builder/component/:uid',
        component: () => import("@/views/builder/Component.vue"),
        children: [
          {
            path: "general",
            component: () => import("@/views/builder/sub/General.vue"),
            alias: ['']
          },
          {
            path: "columns",
            component: () => import("@/views/builder/sub/Columns.vue"),
          },
          {
            path: "filters",
            component: () => import("@/views/builder/sub/Filters.vue"),
          },
          {
            path: "actions",
            component: () => import("@/views/builder/sub/Actions.vue"),
          },
        ]
      },
      {
        path: "/template",
        name: "Template",
        component: () => import("@/views/template/Template.vue")
      },
      {
        path: '/template/component/:uid',
        name: "Component",
        component: () => import("@/views/template/Component.vue"),
        children: [
          {
            path: "general",
            component: () => import("@/views/template/component/General.vue"),
            alias: ['']
          },
          {
            path: "Columns",
            component: () => import("@/views/template/component/Columns.vue")
          },
          {
            path: "Actions",
            component: () => import("@/views/template/component/Actions.vue")
          },
          {
            path: "Fields",
            component: () => import("@/views/template/component/Fields.vue")
          },
          {
            path: "Filters",
            component: () => import("@/views/template/component/Filters.vue")
          },
        ]
      },
    ]
  },
  {
    path: "/sign-in",
    name: "sign-in",
    component: () => import("@/views/auth/SignIn.vue")
  },
  {
    path: "/sign-up",
    name: "sign-up",
    component: () => import("@/views/auth/SignUp.vue")
  },
  {
    path: "/password-reset",
    name: "password-reset",
    component: () => import("@/views/auth/PasswordReset.vue")
  },
  {
    path: "/404",
    name: "404",
    component: () => import("@/views/error/Error404.vue")
  },
  {
    path: "/500",
    name: "500",
    component: () => import("@/views/error/Error500.vue")
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404"
  }
];

const router = createRouter({
  history: createWebHashHistory(),
  routes
});


router.beforeEach(async (to, from, next) => {
  store.commit(Mutations.RESET_LAYOUT_CONFIG);

  const appComponentStore = useAppComponentsStore();
  const toolbarStore = useToolbarStore();
  const authStore = useAuthStore();

  toolbarStore.resetToolbarStore();

  appComponentStore.$reset();
  // await authStore.verifyAuth();


  if (to.meta.middleware == "auth") {
    if (authStore.isAuthenticated) {
      next();
    } else {
      await authStore.verifyAuth();

      if (authStore.isAuthenticated) {
        next();
      } else {
        next({ name: "sign-in" });
      }
    }
  } else {
    next();
  }

  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});

// router.afterEach((to) => {
//   const authStore = useAuthStore();
//           // Add interceptor for handling 401 unauthorized responses
//           axios.interceptors.response.use(
//             (response) => response,
//             (error) => {
//               if (error.response && error.response.status === 401 || error.response && error.response.status === 403) {
//                 authStore.purgeAuth();
//                 router.push({ name: 'sign-in' })
//               }
//               // return Promise.reject(error);
//             }
//           );
// })


export default router;